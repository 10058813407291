<template>
  <!-- Añadir clase active a las actividades activas -->
  <div :class="['card', 'card--activity', { active: isCurrentlyOrIndefinitelyActive }]">
    <div class="card__labels">
      <div class="card__labels-side">
        <span v-if="isCurrentlyOrIndefinitelyActive" class="label label--secondary">{{$t('activitycard.activa')}}</span>
      </div>
      <div class="card__labels-side-rigth">
        <span  v-if="$i18n.locale == 'es'" class="label label--primary">{{myActivity.subject.name}}</span>
        <span  v-else-if="$i18n.locale == 'en'" class="label label--primary">{{myActivity.subject.name_en}}</span>
        
        <span v-if="$i18n.locale == 'es'" class="label label--primary">{{myActivity.grade.name}}</span>
        <span v-else-if="$i18n.locale == 'en'" class="label label--primary">{{myActivity.grade.name_en}}</span>
        <span class="label label--primary">{{myActivity.language}}</span>
      </div>
    </div>
    <div class="card__top">
      <!-- <div class="card__icon" v-if="myActivity.file_url">
        <img class="card__icon-image" :src="myActivity.file_url">
      </div> -->
      <div class="card__titles">
        <p class="subtitle">{{myActivity.title}}</p>
      </div>
    </div>
    <div class="card__actions">
      <div class="card__actions-side">
        <div v-if="isCurrentlyOrIndefinitelyActive" class="code code--in-card">
          <div class="code__icon">
            <a href="#">
              <img class="code__image" src="@/assets/images/icons/copy.svg" @click="copy(true)">
            </a>
          </div>
          <pre class="code__content"><code>{{activityLink}}</code></pre>
        </div>
      </div>
      <div class="card__actions-side">
        <router-link :to="{ name: 'ActivityShow', params: { activityId: activity.id } }" class="btn btn--primary btn--xs">{{$t('activitycard.ver')}}</router-link>      
        <!--<router-link :to="{ name: 'ActivityShow', params: { activityId: activity.id } }" class="btn btn--primary btn--xs">{{$t('activitycard.ver')}}</router-link>-->
        <div class="dropdown">
          <div v-if="this.buttonDots== false" class="container_imgdots">
          <img class="img_dots" src="@/assets/images/icons/more.svg" @click.prevent="activateButtonDots">
          </div>
          <div v-else class="container_x" @click.prevent="activateButtonDots">
          <p class="simbolo_x">x</p>
          </div>
          <div :class="{'dropdown_options_dots_on':buttonDots, 'dropdown_options_dots_off':!buttonDots }">
            <button v-if="isCurrentlyOrIndefinitelyActive" class="btn_dots_share" @click.prevent="showInformationModal = true">{{$t('activitycard.info')}}</button>
            <button v-else class="btn_dots_share" @click.prevent="displayActivationModal()">{{$t('activitycard.activar')}}</button>
            <button v-if="$store.state.user.role_id === 1" class="btn_dots_assign" @click.prevent="displayAssignedModal()">{{$t('activitycard.assignment')}}</button>
          </div>
        </div> 
      </div>
    </div>
  </div>

  <!-- activation modal -->
  <modal v-if="showActivationModal" @close="showActivationModal = false">
    <template #title>{{$t('activitycard.activar')}}</template>
    <template #description>{{$t('activitycard.description')}}</template>
    <template #content>
      <form id="activationform" @submit.prevent="activateActivity()">
        <form-input v-if="$i18n.locale == 'es'" type="radioGroup" :groupOptions="activationKinds" v-model="activation.kind">
          {{$t('activitycard.timehabili')}}
        </form-input>
        <form-input v-else-if="$i18n.locale == 'en'" type="radioGroup" :groupOptions="activationKindsEn" v-model="activation.kind">
          {{$t('activitycard.timehabili')}}
        </form-input>

        <div v-if="activation.kind === 'FIXED'" class="grid grid-2">

              <div v-if="$i18n.locale == 'es'">
                <div :style="text">
                  {{$t('activitycard.desde')}}
                </div>
                <datepicker :style="calendar" v-model="activation.startDate" inputFormat="dd-MM-yyyy"  :lower-limit="lowerlimit" :locale="dateEs"/>

                <div :style="text">
                  {{$t('activitycard.hasta')}}
                </div>
                <datepicker :style="calendar" v-model="activation.endDate" inputFormat="dd-MM-yyyy"  :lower-limit="activation.startDate" :locale="dateEs"/>
              </div>

              <div v-else-if="$i18n.locale == 'en'">
                <div :style="text">
                  {{$t('activitycard.desde')}}
                </div>
                <datepicker :style="calendar" v-model="activation.startDate" inputFormat="dd-MM-yyyy"  :lower-limit="lowerlimit" :locale="dateEn"/>

                <div :style="text">
                  {{$t('activitycard.hasta')}}
                </div>
                <datepicker :style="calendar" v-model="activation.endDate" inputFormat="dd-MM-yyyy"  :lower-limit="activation.startDate" :locale="dateEn"/>
              </div>


        </div>
      </form>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showActivationModal = false">{{$t('activitycard.cerrar')}}</button>
      <button type="submit" class="btn btn--primary" form="activationform" :disabled="activateButtonDisabled">{{$t('activitycard.activar')}}</button>
    </template>
  </modal>
  <modal v-if="showInformationModal" @close="showInformationModal = false">
    <template #title>{{$t('activitycard.title')}}</template>
    <template v-if="myActivity.is_currently_active" #description>{{$t('activitycard.infodisp')}} <strong>{{activityStartDate}}</strong> {{$t('activitycard.al')}}  <strong>{{activityEndDate}}</strong></template>
    <template v-else-if="myActivity.is_active" #description>{{$t('activitycard.unlimiteddisp')}}</template>
    <template #content>
      <p>{{$t('activitycard.copylink')}}</p>
      <div class="code_lesslink">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg"  @click="copyModal('enlaceactividad')">
          </a>
        </div>
       <pre class="code__content"><code>{{activityLink}}</code></pre>
      </div>
      <p>{{$t('activitycard.copylinksecond')}}</p>
       <div class="code_lesslink">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg"  @click="copyModal('enlacegeneral')">
          </a>
        </div>
        <pre class="code__content"><code>{{generalActivityLink}}</code></pre>
      </div>
      <p>{{$t('activitycard.uniccode')}}</p>
      <div class="code">
        <div class="code__icon">
          <a href="#">
            <img class="code__image" src="@/assets/images/icons/copy.svg" @click="copyModal('enlacecode')">
          </a>
        </div>
        <pre class="code__content"><code>{{myActivity.activity_event.code}}</code></pre>
      </div>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showInformationModal = false">{{$t('activitycard.cerrar')}}</button>
      <router-link :to="{ name: 'ActivityShow', params: { activityId: myActivity.id } }" class="btn btn--primary">{{$t('activitycard.viewactivity')}}</router-link>
    </template>
  </modal>

 <modal v-if="showAssignedModal" @close="showAssignedModal = false">
    <template #title>{{$t('activitycard.activityassignment')}}</template>
     <template #description>
      <p>{{$t('activitycard.assign')}}</p>
      <p>{{$t('activitycard.unassign')}}</p>
     </template>
    <template #content>
      <div class="search">
        <div class="search__group">
          <div class="ico-search"><img src="@/assets/images/icons/ico-search--black.svg"></div>
          <form>
            <form-input class="input_modal" v-if="$i18n.locale == 'es'" type="search" :autofocus="true" placeholder="Buscar" v-model="searchTerm"></form-input>
          <form-input class="input_modal" v-else-if="$i18n.locale == 'en'" type="search" :autofocus="true" placeholder="Search" v-model="searchTerm"></form-input>
          </form>
        </div>
      </div>
      <div class="assignation_container">
        <div class="scrollable-list">
          <ul class="list-container">
            <li>
              <label class="checkbox_label">
                <label class="name">{{$t('activitycard.selectall')}}</label>
                <input type="checkbox" v-model="selectAll" @change="toggleSelectAll">
              </label>
            </li>
            <li v-for="profesor in filteredProfessors" :key="profesor.id">
              <label class="checkbox_label">
                <span class="name">{{ profesor.email }}</span>
                <input type="checkbox" v-model="profesor.selected" @change="handleCheckboxChange(profesor)">
              </label>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="closeAssignedModal">{{$t('activitycard.cerrar')}}</button>
      <button type="button" class="btn btn--primary" @click.prevent="updateProfesor">{{$t('activitycard.updateassignment')}}</button>
    </template>
</modal>


</template>

<script>
let today = new Date()
today = `${today.getFullYear()}-${`${today.getMonth() + 1}`.padStart(2, '0')}-${`${today.getDate()}`.padStart(2, '0')}`


import Modal from '@/components/Modal'
import FormInput from '@/components/FormInput'
import Datepicker from 'vue3-datepicker'
import { es,en } from 'date-fns/locale'

export default {
  name: 'ActivityCard',
  props: ['activity'],
  components: { Modal, FormInput,Datepicker },
  data () {
    return {
      showActivationModal: false,
      showInformationModal: false,
      lowerlimit:new Date(),
      dateEn: en,
      dateEs: es,
      isCodeLink:true,
      calendar:{
          height: '50px',
          width: '100%',
          maxWidth: '500px',
          padding: 'var(--space-no) var(--space-n)',
          margin: 'var(--space-no)',
          backgroundColor: 'var(--color-white)',
          border: '1px solid var(--color-tertiary--95)',
          borderRadius: 'var(--radius)',
          lineHeight: '28px',
          MsBoxSizing:' border-box',
          OBoxSizing: 'border-box',
          boxSizing: 'border-box',
          "--vdp-selected-bg-color": "var(--color-primary)",
          "--vdp-hover-bg-color": "var(--color-primary)",
          "--vdp-box-shadow": "0 4px 10px 0 rgba(0, 0, 0, 0.8), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
          "--vdp-text-color": "#000000",
          "--vdp-elem-font-size": "1em",

        },
        text:{
          display:' block',
          fontFamily: "RobotoCondensed-Bold",
          fontSize:' var(--caption-xs)',
          textTransform: 'uppercase',
          lineHeight: 'var(--caption)',
          marginBottom: 'var(--space-xs)',
          color: 'var(--color-text)',
          marginLeft: 'var(--space-xs)',
          textAlign: 'left'
        },
      activationKinds: [
        {
          id: 'INDEFINITE',
          name: 'ILIMITADO',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        },
        {
          id: 'FIXED',
          name: 'RANGO DE FECHA',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activationKindsEn: [
        {
          id: 'INDEFINITE',
          name: 'UNLIMITED',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        },
        {
          id: 'FIXED',
          name: 'DATE RANGE',
          icon: require('@/assets/images/icons/ico-alt-quest--blue.svg')
        }
      ],
      activation: {
        kind: null,
        startDate: new Date(),
        endDate: null
      },
      localActivity: null,
      activationDatesLimits: {
        minStartDate: today,
        maxStartDate: null,
        minEndDate: today
      },
      buttonDots: false,
      showAssignedModal: false,
      list_professors:null,
      selectAll: false,
      searchTerm: '',
      selectedProfessors: [],
    }
  },
  computed: {
    myActivity () {
      const myActivity = this.localActivity || this.activity
      myActivity.grade = this.activity.grade
      myActivity.subject = this.activity.subject
       myActivity.language = this.activity.language
      return myActivity
    },
    isCurrentlyOrIndefinitelyActive () {    
      return (this.myActivity.activity_event || {}).is_active || this.myActivity.is_currently_active
    },
    activityLink () {
      if (!this.myActivity.activity_event) return false
      return `${window.location.origin}${this.$router.getRoutes().find(r => r.name ==='AnswerActivity').path}`.replace(':code',`${this.myActivity.activity_event.code}?name=meaning`)
    },
      generalActivityLink () {
      if (!this.myActivity.activity_event) return false
      return `${window.location.origin}${this.$router.getRoutes().find(r => r.name ==='AccessActivity').path}/`
    },
    activityStartDate () {
      if(this.$i18n.locale == 'en') return new Date(`${this.myActivity.activity_event.start_date}T00:00:00`).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' } )
      return new Date(`${this.myActivity.activity_event.start_date}T00:00:00`).toLocaleDateString('es-CL', { day: 'numeric', month: 'long', year: 'numeric' } )
    },
    activityEndDate () {
      if(this.$i18n.locale == 'en') return new Date(`${this.myActivity.activity_event.end_date}T00:00:00`).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' } )
      return new Date(`${this.myActivity.activity_event.end_date}T00:00:00`).toLocaleDateString('es-CL', { day: 'numeric', month: 'long', year: 'numeric' } )
    },
    activateButtonDisabled () {
      /* The 'Activar' button on the activation modal should be disabled if:
      No kind has been selected OR (
        the selected kind is 'FIXED' AND (
          either startDate or endDate are missing
          OR startDate > endDate
          OR startDate < minStartDate
          OR startDate > maxStartDatel
          OR endDate < minEndDate
        )
      ) */

      
      return !this.activation.kind || (
        this.activation.kind === 'FIXED' && (
          !(this.activation.startDate && this.activation.endDate)
          || this.activation.startDate > this.activation.endDate
          || this.activation.startDate < this.activationDatesLimits.minStartDate
          || this.activation.startDate > this.activationDatesLimits.maxStartDate
          || this.activation.endDate < this.activationDatesLimits.minEndDate
        )
      )
    },
    filteredProfessors() {
      if (!this.searchTerm) {
        return this.list_professors;
      }
      const searchTermLower = this.normalizeString(this.searchTerm.toLowerCase());
      return this.list_professors.filter((professor) =>{
          const emailLower = this.normalizeString(professor.email.toLowerCase());
          //const firstNameLower = this.normalizeString(professor.first_name.toLowerCase());
          //const lastNameLower = this.normalizeString(professor.last_name.toLowerCase());
          return (
            emailLower.includes(searchTermLower)
            //lastNameLower.includes(searchTermLower)
          );
    });
    },
  },

  async mounted() {
    await this.loadAssignedProfessors();
  },

  methods: {
    displayActivationModal () {
      this.activation = {
        kind: null,
        startDate: new Date(),
        endDate: null
      }
      this.showActivationModal = true
    },
    async activateActivity () {
      this.submitting = true
      const data = (await this.$axios.post('activations', {
        activityId: this.myActivity.id,
        kind: this.activation.kind,
        startDate: this.activation.startDate,
        endDate: this.activation.endDate,
      })).data
      this.localActivity = this.$utils.cloneObj(this.activity)
      this.localActivity.activity_event = data.activity.activity_event
      this.localActivity.is_currently_active = data.activity.is_currently_active
      this.showActivationModal = this.submitting = false
      this.showInformationModal = true
    },
      copy(isCodeLink){
        if(isCodeLink == true){
          navigator.clipboard.writeText(this.activityLink)
        }
    },
    copyModal(enlace){
      if(enlace == 'enlaceactividad'){
        navigator.clipboard.writeText(this.activityLink)
      }if(enlace == 'enlacegeneral'){
        navigator.clipboard.writeText(this.generalActivityLink)
      }if(enlace == 'enlacecode'){
        navigator.clipboard.writeText(this.myActivity.activity_event.code)
      }
    },
    activateButtonDots(){
      this.buttonDots = !this.buttonDots        
    },

    async displayAssignedModal(){
      this.showAssignedModal=true
      const res = await this.$axios.get(`assigned-activities/${this.myActivity.id}`)
      this.list_professors = res.data
    },
    toggleSelectAll(){
      this.list_professors.forEach((profesor)=>{
        profesor.selected = this.selectAll
        this.handleCheckboxChange(profesor)
      })
    },
    handleCheckboxChange(professor) {
        if (professor.selected) {
          this.selectedProfessors.push(professor.id);
        } else {
          const index = this.selectedProfessors.indexOf(professor.id);
          if (index !== -1) {
            this.selectedProfessors.splice(index, 1);
          }
        }
      },
    closeAssignedModal(){
      this.showAssignedModal = false
      this.selectAll =false

    },
    async updateProfesor() {
      try {
        const activity_id = this.myActivity.id;
        const data = { professors: this.selectedProfessors };
        await this.$axios.post(`assigned-activities/${activity_id}`, data);
        this.showAssignedModal = false;
      } catch (error) {
        console.error('Error updating professors:', error);
      }
    },
    async loadAssignedProfessors() {
      try {
        const activity_id = this.myActivity.id;
        const response = await this.$axios.get(`assigned-activities/${activity_id}/professors`);
        this.selectedProfessors = response.data.professors.map((professor) => professor.user_id);
      } catch (error) {
        console.error('Error loading assigned professors:', error);
      }
    },
      normalizeString(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  },
  },
  watch: {
    'activation.startDate' () {
      this.activationDatesLimits.minEndDate = this.activation.startDate ? this.activation.startDate : today
    },
    'activation.endDate' () {
      this.activationDatesLimits.maxStartDate = this.activation.endDate // ? this.activation.endDate : today
    }
  }
}
</script>

<style>
.card__labels-side-rigth{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}
.grid{
  justify-content: center;
}

.code_lesslink{
display: inline-flex;
  align-items: center;
  background-color: var(--color-tertiary--95);
  border-radius: var(--radius);
  padding: var(--space-s) var(--space-s);
  width: auto;
  margin-bottom: var(--space-n);
}

.code_lesslink .code__content {
    margin: var(--space-no);
    padding: var(--space-no) var(--space-s);
}

.modal-backdrop .modal--large {
    width: 100%;
    max-width: 830px;
}

@media (max-width: 428px){
.code_lesslink{
  display: inline-flex;
  align-items: center;
  background-color: var(--color-tertiary--95);
  border-radius: var(--radius);
  margin-bottom: var(--space-n);
  width: 90%;
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow: hidden;
}
}

.dropdown_options_dots_on{
    display: flex !important;
    flex-direction: column;
    background-color: var(--color-white);
    position: absolute;
    right: 0;
    min-width: 7rem;
    height: 3rem;
    z-index: 9;
    border-radius: var(--radius);
    -ms-box-shadow: 0 8px 18pt 0 rgba(0, 0, 0, 0.06);
    -o-box-shadow: 0 8px 18pt 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 8px 18pt 0 rgba(0, 0, 0, 0.06);
    margin-top: 0.3rem;
}
.dropdown_options_dots_off{
  display: none;
}
.container_imgdots{
  display: flex;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}

.container_imgdots:hover {
  filter: brightness(0.91);
}

.img_dots{
  transform: rotate(90deg);
  filter: invert(100%);
  cursor: pointer;

  }

.dropdown {
  margin-left: 0.5rem;
}

.buttons_dropdown{
background-color: transparent;
border: none;
}

.container_x{
  display: flex;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container_x:hover {
  filter: brightness(0.91);
}
.simbolo_x{
  color: white;
}
.input_modal{
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.assignation_container{
  display: flex;
  justify-content: center;
}

.scrollable-list {
  height: 10rem;
  width: 30rem;
  overflow-y: auto;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.1);  
}

.checkbox_label {
  display: flex;
  align-items: center;
}

.name {
  flex: 1;
  margin-right: 10px; /* Ajusta el valor según tu preferencia */
}

.btn_dots_share{
  background-color: #fff;
  border: none;
  padding-top: 0.5rem;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  
}

.btn_dots_assign{
  background-color: #fff;
  border: none;
  margin-top: 0.5rem;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
  
}

.btn_dots_share:hover{
  filter: brightness(0.92);
  
}

.btn_dots_assign:hover{
  filter: brightness(0.92);
  
}

</style>
