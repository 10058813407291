<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('activities.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('activities.title')}}</template>
    <template #subtitle>{{$t('activities.subtitle')}} <span class="meaning">+MEANING</span></template>



    <section class="tabs">
      <!--<div :class="['tab', { 'tab--active': displayingPublic }]" @click.prevent="fetchActivities({ isPublic: true }),showPublicTab()">{{$t('activities.btnpublic')}}</div>
      <div :class="['tab', { 'tab--active': displayingPrivate }]" @click.prevent="fetchActivities({ isPublic: false }),showPrivateTab()">{{$t('activities.btnprivate')}}</div>
      <div :class="['tab', { 'tab--active': displayingAssigned }]" @click.prevent="fetchActivities({ isAssigned: true }),showAssignedTab()">{{$t('activities.btnassigned')}}</div>
      -->
      
      <div :class="['tab', { 'tab--active': displayingPublic }]" @click.prevent="showPublicTab()">{{$t('activities.btnpublic')}}</div>
      <div :class="['tab', { 'tab--active': displayingPrivate }]" @click.prevent="showPrivateTab()">{{$t('activities.btnprivate')}}</div>
      <div :class="['tab', { 'tab--active': displayingDefault }]" @click.prevent="showDefault()">{{$t('activities.btndefault')}}</div>
      <div v-if="$store.state.user.role_id !== 1" :class="['tab', { 'tab--active': displayingAssigned }]" @click.prevent="showAssignedTab()">{{$t('activities.btnassigned')}}</div>
    </section>

    <section class="main-section">
      <div class="search">
        <div class="search__group">
          <div class="ico-search"><img src="@/assets/images/icons/ico-search--black.svg"></div>
          <form @submit.prevent="searchActivities({ performSearchAlways: true })">
            <form-input v-if="$i18n.locale == 'es'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Buscar" @input="searchActivities({ performSearchAlways: false })"></form-input>
          <form-input v-else-if="$i18n.locale == 'en'" v-model.trim="searchTerm" type="search" :autofocus="true" placeholder="Search" @input="searchActivities({ performSearchAlways: false })"></form-input>
          </form>
          <!-- <div class="form__group">
            <input class="form__input" type="search">
          </div> -->
        </div>
      </div>
      <div class="grid grid-3">
        <activity-card v-for="activity in activities" :key="activity.id" :activity="activity"/>
      </div>
    </section>
    <router-link :to="{ name: 'ActivitiesNew' }" class="btn btn--primary btn--circle btn--fixed">
      <img src="@/assets/images/icons/ico-add--white.svg">
    </router-link>
  </base-view-layout>
</template>

<script>
import { inject, reactive } from 'vue'
import BaseViewLayout from '@/components/BaseViewLayout'
import ActivityCard from '@/components/ActivityCard'
import FormInput from '@/components/FormInput'
/* global fbq */

export default {
  name: 'Activities',
  components: { BaseViewLayout, ActivityCard, FormInput },
  setup () {
    const output = {
      activities: reactive([])
    }
    const axios = inject('$axios')
    axios.get('activities', {
      params: {
        expand: ['grade', 'subject','language'],
        is_public: true
      }
    }).then(res => res.data.activities.forEach(activity => output.activities.push(activity)))
    return output
  },
  data () {
    return {
      displayingPublic: true,
      displayingPrivate: false,
      displayingAssigned: false,
      displayingDefault:false,
      searchTermPublicActivities: '',
      searchTermPrivateActivities: '',
      searchTermAssignedActivities: '',
      
      
    }
  },
  methods: {
    showPublicTab() {
      this.displayingPublic = true;
      this.displayingPrivate = false;
      this.displayingAssigned = false;
      this.displayingDefault = false;
      this.fetchPublicActivities();
    },
    showPrivateTab() {
      this.displayingPublic = false;
      this.displayingPrivate = true;
      this.displayingAssigned = false;
      this.displayingDefault = false;
      this.fetchPrivateActivities();
    },
    showAssignedTab() {
      this.displayingPublic = false;
      this.displayingPrivate = false;
      this.displayingDefault = false;
      this.displayingAssigned = true;
      this.fetchAssignedActivities();
    },
    showDefault() {
      this.displayingPublic = false;
      this.displayingPrivate = false;
      this.displayingAssigned = false;
      this.displayingDefault = true;
      this.fetchDefaultActivities()
    },
    /*async fetchActivities ({ isPublic }) {
      if (this.displayingPublic === isPublic){
        return
      }else{ 
      this.displayingPublic = isPublic
      const activities = (await this.$axios.get('activities', {
        params: {
          expand: ['grade', 'subject','language'],
          is_public: isPublic,
          searchTerm: this.searchTerm
        }
      })).data.activities
      this.activities.splice(0)
      this.activities.push(...activities)  
      }      
    },*/
    async fetchAssignedActivities() {
      const user_id = this.$store.state.user.id
      const activities = (await this.$axios.get(`assigned-activities/${user_id}/assigned`,{
        params:{
          expand: ['grade', 'subject', 'language'],
          searchTerm: this.searchTerm
        }
      })).data.activities
      this.activities.splice(0)
      this.activities.push(...activities)      
    },
    async fetchPublicActivities() {
      const activities = (await this.$axios.get('activities', {
        params: {
          expand: ['grade', 'subject', 'language'],
          is_public: true, // Set is_public to true to get public activities
          searchTerm: this.searchTerm
        }
      })).data.activities;
      this.activities.splice(0);
      this.activities.push(...activities);
    },
    async fetchPrivateActivities() {
      const activities = (await this.$axios.get('activities', {
        params: {
          expand: ['grade', 'subject', 'language'],
          is_public: false, // Set is_public to false to get private activities
          searchTerm: this.searchTerm
        }
      })).data.activities;
      this.activities.splice(0);
      this.activities.push(...activities);
      
    },
    async fetchDefaultActivities() {
      const user_id = this.$store.state.user.id
      const activities = (await this.$axios.get(`activities/${user_id}/default`, {
        params: {
          expand: ['grade', 'subject', 'language'],
          is_public: false, // Set is_public to false to get private activities
          searchTerm: this.searchTerm
        }
      })).data.activities;
      this.activities.splice(0);
      this.activities.push(...activities);
      console.log(activities)
    },
    async searchActivities ({ performSearchAlways }) {
      if (this.searchTerm && !performSearchAlways) return
      if(this.displayingPublic || this.displayingPrivate){
        this.activities.splice(0)
        this.activities.push(...(await this.$axios.get('activities', {
        params: {
          expand: ['grade', 'subject','language'],
          searchTerm: this.searchTerm
        }
      })).data.activities)
      }else if(this.displayingAssigned){
        const user_id = this.$store.state.user.id
        this.activities.splice(0)
        this.activities.push(...(await this.$axios.get(`assigned-activities/${user_id}/assigned`, {
        params: {
          expand: ['grade', 'subject','language'],
          searchTerm: this.searchTerm
        }
      })).data.activities)

      }else if(this.displayingDefault){
        const user_id = this.$store.state.user.id
        this.activities.splice(0)
        this.activities.push(...(await this.$axios.get(`activities/${user_id}/default`, {
        params: {
          expand: ['grade', 'subject','language'],
          searchTerm: this.searchTerm
        }
      })).data.activities)
      }
      
    },

  },
  computed: {
    searchTerm: {
      get () {
        return this.displayingPublic ? this.searchTermPublicActivities : this.searchTermPrivateActivities
      },
      set (val) {
        if (this.displayingPublic) {
          this.searchTermPublicActivities = val
        } else {
          this.searchTermPrivateActivities = val
        }
      }
    }
  },
  mounted (){
    // TODO: improve user logout
    this.$cookies.remove('jwt')
    
    // Meta pixel code
    function loadFacebookPixel() {
    !function (f, b, e, v, n, t, s) { 
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  }
  window.addEventListener('load', function() {
  // Carga la biblioteca de Facebook Pixel
  loadFacebookPixel();

  // Inicializa el píxel de Facebook y realiza un seguimiento de la vista de página
  fbq('init', '1023050025682178');
  fbq('track', 'PageView');
  });
}
}
</script>


<style>


@media (max-width: 428px){

.card{

  margin: 0.5rem;

}

}


</style>